<template>
  <div
    v-if="hotspot.type"
    :class="`hotspot ${`hotspot__${hotspot.type.value}`} ${
      clickCount > 0 && isTouchDevice ? 'touched' : ''
    }`"
    :id="hotspot.id"
    :data-title="hotspot.linkedPano ? hotspot.linkedPano.title : ''"
    :data-slug="hotspot.linkedPano ? hotspot.linkedPano.slug : ''"
    :data-name="hotspot.linkedPano ? hotspot.linkedPano.name : ''"
    :data-ath="hotspot.ath"
    :data-atv="hotspot.atv"
    :data-type="hotspot.type.value"
    @click="handleClick"
    v-click-outside="handleClickOutside"
  >
    <div :class="`hotspot__inner hotspot__inner__${hotspot.type.value}`">
      <img
        :src="`/assets/img/icon-${hotspot.type.value}.svg`"
        alt="hotspot icon"
      />
    </div>

    <div
      :class="`hotspot__content ${`hotspot__content--${hotspot.type.value}`}`"
    >
      <span v-if="hotspot.type.value == 'navigation'">
        {{ hotspot.linkedPano.title }}
      </span>
      <span
        v-else-if="
          hotspot.type.value == 'info' || hotspot.type.value == 'image'
        "
      >
        {{ hotspot.modal.title }}
      </span>
      <div
        v-if="hotspot.type.value !== 'small-info'"
        class="hotspot__content__bg"
      ></div>
    </div>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";
import vClickOutside from "v-click-outside";

export default {
  name: "Hotspot",
  props: {
    tour: Object,
    pano: Object,
    hotspot: Object,
    clickedOutside: Boolean,
  },
  data() {
    return {
      isTouchDevice: isTouchDevice(),
      clickCount: 0,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    handleClickOutside(event) {
      this.clickCount = 0;
      if (
        document.getElementById(this.hotspot.id) &&
        document
          .getElementById(this.hotspot.id)
          .classList.contains("hotspot__small-info")
      ) {
        document.getElementById(this.hotspot.id).classList.remove("touched");
      }
    },
    updateClasses() {
      document.querySelectorAll(".hotspot__small-info").forEach((el) => {
        el.classList.remove("touched");
      });
      document.querySelectorAll(".locator__touched").forEach((el) => {
        el.classList.remove("locator__touched");
      });
      document
        .getElementById(`hotspot__locator--${this.hotspot.id}`)
        .classList.add("locator__touched");
    },
    handleClick() {
      this.updateClasses();
      this.clickCount++;

      if (
        this.isTouchDevice &&
        this.clickCount < 2 &&
        this.hotspot.type.value !== "info" &&
        this.hotspot.type.value !== "image"
      )
        return;

      let data = {
        hotspot_type: this.hotspot.type.value,
      };

      switch (this.hotspot.type.value) {
        case "navigation":
          this.$emit("navigate", this.hotspot.linkedPano);
          data.nav_from = this.pano.title;
          data.nav_to = this.hotspot.linkedPano.title;
          break;
        case "info":
          this.$emit("info", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = this.hotspot.modal.hasVideo
            ? "Video modal"
            : this.hotspot.modal.images && this.hotspot.modal.images.length > 0
            ? "Image modal"
            : "Text modal";
          break;
        case "image":
          this.$emit("info", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = this.hotspot.modal.hasVideo
            ? "Video modal"
            : this.hotspot.modal.images && this.hotspot.modal.images.length > 0
            ? "Image modal"
            : "Text modal";
          break;
        default:
          break;
      }

      // Fire GTM event for click
      if (data) {
        this.sendGtmEvent(
          "click-hotspot",
          data,
          this.tour.title,
          this.pano.title
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$hotspot-dimension: 44px;
$border-width: 2px;

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: $hotspot-dimension;
  height: $hotspot-dimension;
  background: $color-white;

  display: grid;
  place-content: center;

  box-shadow: 0 0 32px 8px rgba(0, 0, 0, 0.2);
  transition: all $basic-transition-200;
  border: $border-width solid $color-border;

  img {
    filter: invert(13%) sepia(72%) saturate(5842%) hue-rotate(329deg)
      brightness(79%) contrast(110%);
  }

  &__inner {
    width: $hotspot-dimension;
    height: $hotspot-dimension;
    transition: all $basic-transition-200;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      transition: all $basic-transition-200;
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: -#{$border-width};
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    height: $hotspot-dimension;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1.3rem 3.8rem 1.3rem 5rem;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-transition-200, border $basic-transition-300;

    span {
      @include fontSize(1.3rem, 1.3, 1.3rem);
      opacity: 0;
      color: $color-white;
      font-weight: bold;
      transition: all $basic-transition-500;
    }

    &__bg {
      border: calc(#{$border-width} + 1px) solid $color-border;
      background: $color-primary;
      width: 0%;
      // height: calc(#{$hotspot-dimension} + 2 * #{$border-width});
      height: 100%;
      left: 0;
      transition: all $basic-transition-200;
      position: absolute;
      z-index: -1;
    }
  }

  &__navigation:hover,
  &__navigation.touched {
    border: $border-width solid transparent;
    background: transparent;

    .hotspot__inner {
      background: transparent;
      width: calc(#{$hotspot-dimension} - 4px);
      height: calc(#{$hotspot-dimension} - 4px);

      img {
        opacity: 1;
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
          brightness(106%) contrast(106%);
      }
    }

    .hotspot__content {
      opacity: 1;
      pointer-events: all;

      span,
      p {
        opacity: 1;
      }

      &__bg {
        width: 100%;
      }
    }
  }

  &__info:hover,
  &__image:hover {
    background: $color-primary;
    border: $border-width solid transparent;

    img {
      opacity: 1;
      filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
        brightness(106%) contrast(106%);
    }
  }
}
</style>
