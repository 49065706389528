var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.params.tour)?_c('div',{staticClass:"krpano__wrapper",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.modal = false}}},[_c('transition-group',{attrs:{"name":"fade-splash","mode":"out-in"}},[(_vm.showingSplash && _vm.loading)?_c('SplashScreen',{key:"splash",on:{"close":function($event){_vm.showingSplash = false}}}):_vm._e()],1),_c('Modal',{staticClass:"modal",attrs:{"open":_vm.modal,"content":_vm.modalContent},on:{"close":function($event){_vm.modal = false}}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.overview)?_c('Overview',{staticClass:"overview gui__element",attrs:{"categories":_vm.categories,"inTour":true},on:{"close":function($event){_vm.overview = false},"load-pano":function($event){return _vm.loadPano(null, $event)},"open-detail":function ($event) {
          _vm.loadPano(null, $event.panos[0]);
          _vm.overview = false;
          _vm.detail = true;
        }}}):_vm._e()],1),(_vm.currentPano && _vm.currentPano.category)?_c('DetailedOverview',{staticClass:"detailed-overview",attrs:{"open":_vm.detail,"category":_vm.currentPano && _vm.currentPano.category ? _vm.currentPano.category : null,"panos":_vm.panos},on:{"close":function($event){_vm.detail = false},"open-overview":function($event){_vm.detail = false;
      _vm.overview = true;},"load-pano":function($event){return _vm.loadPano(null, $event.pano, $event.lookat)}}}):_vm._e(),_c('router-link',{staticClass:"gui__logo gui__element gui__element--top-left",attrs:{"to":("/" + _vm.$locale)}},[_c('img',{attrs:{"src":"/assets/img/logo.svg","alt":"Client logo"}})]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.modal && !_vm.overview && !_vm.detail)?_c('div',{staticClass:"gui"},[(_vm.webVRCapable && !_vm.webVRActive)?_c('div',{staticClass:"gui__webvr gui__element gui__element--top-center"},[_c('a',{attrs:{"href":"#!"},on:{"click":function($event){$event.preventDefault();return _vm.startWebVR.apply(null, arguments)}}},[_vm._v("Bekijk in VR")])]):_vm._e(),(_vm.webVRActive)?_c('div',{staticClass:"gui__webvr gui__element gui__element--top-center"},[_c('a',{attrs:{"href":"#!"},on:{"click":function($event){$event.preventDefault();return _vm.exitWebVR.apply(null, arguments)}}},[_vm._v("Verlaat VR")])]):_vm._e(),(_vm.currentPano && _vm.currentPano.category)?_c('div',{class:("gui__title gui__element gui__element--bottom-left " + (_vm.currentPano.category.description ? 'gui__title--description' : '') + " " + (_vm.descriptionOpen ? 'open' : ''))},[_c('h1',[_vm._v(_vm._s(_vm.currentPano.category.title))]),(_vm.currentPano.category.description)?_c('ReadMore',{attrs:{"text":_vm.currentPano.category.description,"max":0,"open":_vm.descriptionOpen},on:{"read-less":function($event){_vm.descriptionOpen = false},"read-more":function($event){_vm.descriptionOpen = true},"open-detail":function($event){_vm.detail = true}}}):_vm._e()],1):_vm._e(),_c('a',{staticClass:"gui__overview gui__overview--open gui__element gui__element--top-right",attrs:{"href":"#!"},on:{"click":function($event){$event.preventDefault();return (function () {
            _vm.descriptionOpen = false;
            if (_vm.currentPano && _vm.currentPano.category) {
              _vm.detail = true;
            } else {
              _vm.overview = true;
            }
          }).apply(null, arguments)}}},[_c('div',{staticClass:"open-icon"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"})]),_c('span',[_vm._v(_vm._s(_vm.$t.overviewMenu))])]),_c('div',{staticClass:"logo__wrapper logo__wrapper--outside gui__element gui__element--bottom-right"},[_c('img',{attrs:{"src":"/assets/img/logo-text.svg","alt":"Belfius text logo"}})])]):_vm._e()]),_c('div',{attrs:{"id":"krpano"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }