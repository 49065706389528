import axios from "axios";

axios.defaults.baseURL = `${window.location.origin}${process.env.VUE_APP_API_URL}`;
axios.defaults.params = {
  locale: localStorage.getItem("locale"),
};

const virtualTourApi = {
  getAvailableLocales: async () => {
    return await axios.get(`/locales`);
  },
  getAllTours: async () => {
    return await axios.get(`/`);
  },
  getPanosForTour: async (slug) => {
    return await axios.get(`?slug=${slug}`);
  },
  getPano: async (name) => {
    return await axios.get(`/pano?name=${name}`);
  },
  getHotspot: async (panoName, hotspotName) => {
    return await axios.get(
      `/pano/hotspot?panoName=${panoName}&hotspotName=${hotspotName}`
    );
  },
  getCategories: async () => {
    return await axios.get(`/categories`);
  },
};

export default virtualTourApi;
