<template>
  <div class="home">
    <router-link
      @click.native="overview = false"
      class="gui__logo gui__element gui__element--top-left"
      :to="`/${$locale}`"
      ><img src="/assets/img/logo.svg" alt="Client logo"
    /></router-link>

    <Languages class="languages gui__element gui__element--top-right" />

    <transition name="fade">
      <Overview
        class="overview gui__element"
        v-if="overview"
        :categories="categories"
        :inTour="false"
        @close="overview = false"
      />
    </transition>

    <div class="home__info gui__element">
      <h1>{{ $t.introTitle }}</h1>
      <p>{{ $t.introInfo }}</p>
      <p class="home__info--emphasized">{{ $t.introSlogan }}</p>
      <a class="btn" @click.prevent="overview = true" href="#!">
        {{ $t.introButton }}
      </a>
      <div class="logo__wrapper logo__wrapper--inside">
        <img src="/assets/img/logo-text.svg" alt="Belfius text logo" />
      </div>
    </div>

    <div
      class="logo__wrapper logo__wrapper--outside gui__element gui__element--bottom-right"
    >
      <img src="/assets/img/logo-text.svg" alt="Belfius text logo" />
    </div>

    <div class="home__video-bg">
      <div class="home__video--overlay"></div>
      <video
        class="home__video"
        autoplay
        playsinline
        muted
        loop
        controlsList="nodownload"
        id="bg-video"
        poster="/assets/img/poster.png"
      >
        <source
          src="https://player.vimeo.com/progressive_redirect/playback/667289482/rendition/1080p/1080p.mp4?loc=external&signature=3f94e227ae04722b96df22c144583a5fadf8fda7383c92f9fed5c55db32b7ef0"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</template>

<script>
import virtualTourApi from "@/api";
import Overview from "@/components/Overview.vue";
import Languages from "@/components/Languages";

export default {
  name: "Home",
  components: {
    Overview,
    Languages,
  },
  data() {
    return {
      overview: false,
      tours: [],
      categories: [],
    };
  },
  mounted() {
    // virtualTourApi.getAllTours().then((res) => {
    //   this.tours = res.data;
    // });
    virtualTourApi.getCategories().then((res) => {
      this.categories = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__info {
    background: $color-primary;
    color: $color-text-inverse;
    padding: 3.2rem 2.4rem;

    width: 100%;
    max-width: unset;
    left: 0;
    right: 0;
    bottom: 0;

    display: grid;
    justify-content: flex-start;
    align-content: space-between;
    grid-gap: 24px;
    overflow: hidden;

    &--emphasized {
      font-weight: bold;
      margin-bottom: 2.4rem;
    }

    @include for-tablet-landscape-up {
      padding: 56px;
      max-width: 50rem;
      width: 40%;
      left: unset;
      right: $ui-padding;
      bottom: calc(#{$ui-padding} + 7.4rem);
    }
  }

  .logo__wrapper {
    max-width: 12rem;

    img {
      width: 100%;
      object-fit: cover;
    }

    &--inside {
      display: block;
      justify-self: flex-end;
    }

    &--outside {
      display: none;
    }

    @include for-tablet-landscape-up {
      &--inside {
        display: none;
      }

      &--outside {
        display: block;
      }
    }
  }

  &__video {
    z-index: -3;
    object-fit: cover;

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      * {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    &--overlay {
      z-index: -2;
      height: 100%;
      width: 100%;
      opacity: 0.7;
      background: lighten($color-black, 25%);
    }
  }

  .gui__logo {
    z-index: 11;
  }

  .overview {
    z-index: 10;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity $basic-transition-500;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
