<template>
  <p>
    <transition name="fade-text" mode="out-in">
      <span :key="visibleText">
        {{ visibleText }}
        <a
          v-if="text.length > max && maxCharVisible < text.length"
          href="#!"
          @click.prevent="handleReadMore()"
        >
          <img
            src="/assets/img/icon-plus.svg"
            alt="plus icon"
            class="icon icon--plus"
          />
          <span>{{ $t.overviewReadMore }}</span>
        </a>
        <a
          v-else-if="text.length > max"
          href="#!"
          @click.prevent="
            () => {
              maxCharVisible = max;
              $emit('read-less');
            }
          "
        >
          <img
            src="/assets/img/icon-plus.svg"
            alt="close icon"
            class="icon icon--close"
          />
          <span>{{ $t.overviewReadLess }}</span></a
        >
      </span>
    </transition>
  </p>
</template>

<script>
export default {
  props: {
    open: { default: false },
    max: { default: 250 },
    text: { default: "" },
  },
  data() {
    return {
      maxCharVisible: this.max,
    };
  },
  watch: {
    open(open) {
      if (!open) {
        this.maxCharVisible = this.max;
      }
    },
  },
  computed: {
    visibleText() {
      if (this.maxCharVisible < this.text.length) {
        // return `${this.text.substring(0, this.maxCharVisible)} ...`;
        return `${this.text.substring(0, this.maxCharVisible)}`;
      } else {
        return `${this.text.substring(0, this.maxCharVisible)}`;
      }
    },
  },
  methods: {
    handleReadMore() {
      if (window.innerWidth <= 900) {
        this.$emit("open-detail");
      } else {
        this.maxCharVisible = this.text.length;
        this.$emit("read-more");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: inline-block;
  line-height: 1.5 !important;

  a {
    display: flex;
    align-items: center;
    display: inline;
    padding: 0 12px;
    transition: font-weight $basic-transition-100;

    &:hover {
      font-weight: bold;
    }

    * {
      color: $color-backdrop;
      font-weight: bold;
    }

    img {
      max-width: 2rem;
      filter: invert(36%) sepia(8%) saturate(1093%) hue-rotate(164deg)
        brightness(96%) contrast(87%);
      margin-right: 1rem !important;
    }
  }
}

.fade-text-enter-active,
.fade-text-leave-active {
  transition: opacity 0.1s linear;
}
.fade-text-enter,
.fade-text-leave-to {
  opacity: 0.3;
}
</style>
