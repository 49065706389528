<template>
  <div class="overview">
    <a
      class="gui__close gui__element gui__element--top-right"
      href="#!"
      @click.prevent="$emit('close')"
    >
      <img src="/assets/img/icon-close.svg" alt="Client logo" />
      <span>{{ $t.overviewClose }}</span>
    </a>

    <section class="swiper__wrapper">
      <h2>{{ $t.overviewTitle }}</h2>
      <div class="swiper">
        <div class="swiper-overlay" id="swiper-overlay--end"></div>
        <div class="swiper-wrapper">
          <article
            v-for="(cat, index) in categories"
            :key="index"
            class="swiper-slide"
          >
            <div @click="handleClick(cat)" class="content">
              <div class="thumb__container">
                <div class="thumb__wrapper">
                  <img :src="cat.thumb" alt="Category thumbnail" />
                </div>
              </div>
              <h3>{{ cat.title }}</h3>
              <p v-if="cat.summary">{{ cat.summary }}</p>
            </div>
            <a href="#!" @click.prevent="handleOpenDetail(cat)">
              <span>{{ $t.overviewView }}</span>
              <img src="/assets/img/icon-go.svg" alt="Arrow icon" />
            </a>
          </article>
        </div>
      </div>
    </section>

    <a
      href="https://poppr.be/"
      class="credit gui__element gui__element--bottom-right"
      rel="noopener noreferrer"
      target="_blank"
    >
      Powered by Poppr
    </a>
  </div>
</template>

<script>
import Swiper, { Mousewheel } from "swiper";

export default {
  name: "Overview",
  props: ["categories", "inTour"],
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    });

    Swiper.use([Mousewheel]);
    const swiper = new Swiper(".swiper", {
      spaceBetween: 42,
      grabCursor: true,
      slidesPerView: "auto",
      mousewheel: true,
      mousewheelControl: true,
    });

    // swiper.on("reachEnd", () => {
    //   document.getElementById("swiper-overlay--end").style.opacity = 0;
    // });
  },
  methods: {
    handleClick(cat) {
      if (this.inTour) {
        this.$emit("load-pano", cat.panos[0]);
        this.$emit("close");
      } else {
        this.$router.push(
          `/${this.$locale}/virtual-tour/${cat.panos[0].tour.slug}/${cat.panos[0].slug}`
        );
      }
    },
    handleOpenDetail(cat) {
      if (this.inTour) {
        this.$emit("open-detail", cat);
      } else {
        this.$router.push(
          `/${this.$locale}/virtual-tour/${cat.panos[0].tour.slug}/${cat.panos[0].slug}?detail=open`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/lib/swiper-bundle.min.css";

.overview {
  background: $color-backdrop;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  .gui {
    &__close {
      background: $color-backdrop--lighter;
      top: 0;
      right: 0;
      padding: 1.6rem $ui-padding-mobile;

      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(274deg)
          brightness(101%) contrast(103%);
      }

      * {
        @include fontSize(1.1rem, 1.3, 1.4rem);
        color: $color-white;
      }

      @include for-tablet-landscape-up {
        background: transparent;
        top: $ui-padding;
        right: $ui-padding;
        padding: unset;
      }
    }
  }

  .swiper {
    padding-right: $ui-padding-mobile;

    @include for-tablet-landscape-up {
      padding-right: $ui-padding;
    }

    &-overlay {
      z-index: 10;
      position: absolute;
      right: 0;
      height: 100%;
      width: 2rem;
      pointer-events: none;
      background: red;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba($color-backdrop, 0.3) 50%,
        rgba($color-backdrop, 0.8) 100%
      );
    }

    &__wrapper {
      min-height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 4.2rem 0 4.2rem $ui-padding-mobile;

      @include for-tablet-landscape-up {
        min-height: 70%;
        padding: 4.2rem 0 4.2rem $ui-padding;
      }

      * {
        color: $color-white;
      }

      .content {
        margin-bottom: 1.6rem;

        @include for-tablet-landscape-up {
          margin-bottom: 4.2rem;
        }
      }

      h2 {
        @include fontSize(4rem, 5.6, 6.5rem);
        margin-bottom: 4.2rem;
      }

      h3 {
        @include fontSize(2.5rem, 2.5, 2.5rem);
        margin-bottom: 0.6rem;
      }

      p {
        @include fontSize(1.4rem, 1.4, 1.4rem);
      }

      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;

        * {
          font-weight: bold;
        }

        img {
          max-width: 2.4rem;
          margin-left: 1rem;
          filter: invert(55%) sepia(15%) saturate(383%) hue-rotate(159deg)
            brightness(88%) contrast(84%);
          transition: all $basic-transition-300;
        }

        @include for-tablet-landscape-up {
          justify-content: flex-start;

          img {
            margin-left: 3rem;
          }
        }
      }

      .thumb {
        &__container {
          height: 20rem;
          width: 100%;
          overflow: hidden;
          margin-bottom: 1.6rem;
        }

        &__wrapper {
          height: 100%;
          width: 100%;
          transition: all $basic-transition-500;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .swiper-slide {
        width: 70%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        * {
          cursor: pointer;
        }

        @include for-tablet-landscape-up {
          width: 35rem;
        }

        .content:hover .thumb__wrapper {
          transform: scale(1.2);
        }

        a:hover img {
          margin-left: 4rem;
        }
      }
    }
  }

  .credit {
    display: block;
    top: auto;
    z-index: 3;
    @include fontSize(1.2rem, 1.2, 1.2rem);
    color: $color-white;
    letter-spacing: 0;
    text-decoration: none;
    white-space: nowrap;
    opacity: 0.4;

    right: 1rem;
    bottom: 1rem;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}
</style>
