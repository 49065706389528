<template>
  <div :class="`modal__container ${open ? 'modal__container_open' : ''}`">
    <a
      class="gui__close gui__element gui__element--top-right"
      href="#!"
      @click.prevent="$emit('close')"
    >
      <img src="/assets/img/icon-close.svg" alt="Client logo" />
      <span>{{ $t.overviewClose }}</span>
    </a>

    <div class="modal__inner modal__inner--content" v-if="content.content">
      <div class="modal__content">
        <h2 class="title">{{ content.title }}, {{ content.year }}</h2>
        <h3 class="subtitle">
          {{ content.artist }}
        </h3>
        <p class="text" v-html="content.content"></p>
      </div>
    </div>

    <div
      class="modal__inner modal__inner--image"
      v-if="content.images && content.images.length > 0"
    >
      <inner-image-zoom :src="content.images[0]" :fullscreenOnMobile="true" />
    </div>
    <div class="modal__outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import InnerImageZoom from "vue-inner-image-zoom";

export default {
  name: "Modal",
  components: {
    "inner-image-zoom": InnerImageZoom,
  },
  props: {
    content: Object,
    open: Boolean,
    close: Function,
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/lib/vue-inner-image-zoom.css";

.modal {
  &__container {
    z-index: 3002;
    display: grid;
    position: absolute;
    background: rgba($color-backdrop, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-transition-500 0.1s;

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .gui__close {
        opacity: 1 !important;

        * {
          opacity: 1 !important;
        }
      }

      .modal__inner {
        overflow: hidden;
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;

        width: 100%;
        height: 100%;

        .modal__content {
          opacity: 1;
        }

        &--image {
          display: grid;
          place-content: center;
          max-width: 90%;
          height: auto;
          opacity: 1 !important;

          * {
            opacity: 1 !important;
          }
        }

        @include for-tablet-landscape-up {
          &--content {
            width: 70%;
            // height: 80%;
            height: auto;
            max-height: 80%;
            max-width: 800px;
          }

          &--image {
            max-width: 70%;
            height: 80%;
          }

          bottom: unset;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .modal__outer {
        pointer-events: all;
        opacity: 0.3;
      }
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    height: 20px;

    transition: height $basic-transition-500 0.1s,
      opacity $basic-transition-500 0.1s;

    .modal__content {
      width: 100%;
      opacity: 0;
      transition: opacity $basic-transition-300 0.5s;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
    }

    &--content {
      background-color: $color-white;
      padding: 5.6rem 2.4rem 2.4rem 2.4rem;

      .title {
        color: $color-black;
        @include fontSize(3.5rem, 3.6, 3.6rem, 1.25);
        margin-bottom: 1.2rem;
        max-width: calc(100% - 6rem);

        @include for-tablet-landscape-up {
          max-width: unset;
        }
      }

      .subtitle {
        color: $color-black;
        @include fontSize(1.6rem, 1.8, 1.8rem);
        margin-bottom: 2.4rem;
        font-weight: 500;
      }

      .text {
        flex-grow: 1;
        @include fontSize(1.5rem, 1.5, 1.5rem);
        padding-right: 3.6rem;
        overflow: auto;
        margin: 0.6rem 1rem 0.6rem 0;

        * {
          color: $color-text;
        }
      }

      @include for-tablet-landscape-up {
        padding: 7.2rem 3.6rem 5.6rem 7.2rem;
      }
    }

    &--image {
      opacity: 0;

      * {
        opacity: 0;
        height: 100%;
      }
    }
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
    z-index: -1;
  }

  .gui {
    &__close {
      z-index: 3004;
      background: $color-white--darker;
      top: 0;
      right: 0;
      padding: 1.6rem $ui-padding-mobile;
      opacity: 0;
      transition: opacity $basic-transition-300 0.1s;

      * {
        @include fontSize(1.1rem, 1.3, 1.4rem);
        color: $color-backdrop;
        opacity: 0;
        transition: opacity $basic-transition-300 0.1s;
      }

      img {
        transition: all $basic-transition-200;
        filter: invert(36%) sepia(8%) saturate(1093%) hue-rotate(164deg)
          brightness(96%) contrast(87%);
      }

      &:hover img {
        transform: rotate(90deg);
      }

      @include for-tablet-landscape-up {
        background: transparent;
        top: $ui-padding;
        right: $ui-padding;
        padding: unset;

        * {
          color: $color-white;
        }

        img {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(274deg)
            brightness(101%) contrast(103%);
        }
      }
    }
  }
}
</style>
