<template>
  <div
    class="krpano__wrapper"
    v-if="$route.params.tour"
    @keydown.esc="modal = false"
  >
    <transition-group name="fade-splash" mode="out-in">
      <SplashScreen
        v-if="showingSplash && loading"
        @close="showingSplash = false"
        key="splash"
      />
    </transition-group>
    <Modal
      class="modal"
      :open="modal"
      :content="modalContent"
      @close="modal = false"
    />

    <transition name="fade">
      <Overview
        class="overview gui__element"
        v-if="overview"
        :categories="categories"
        :inTour="true"
        @close="overview = false"
        @load-pano="loadPano(null, $event)"
        @open-detail="
          ($event) => {
            loadPano(null, $event.panos[0]);
            overview = false;
            detail = true;
          }
        "
      />
    </transition>

    <DetailedOverview
      v-if="currentPano && currentPano.category"
      class="detailed-overview"
      :open="detail"
      :category="
        currentPano && currentPano.category ? currentPano.category : null
      "
      :panos="panos"
      @close="detail = false"
      @open-overview="
        detail = false;
        overview = true;
      "
      @load-pano="loadPano(null, $event.pano, $event.lookat)"
    />

    <router-link
      class="gui__logo gui__element gui__element--top-left"
      :to="`/${$locale}`"
      ><img src="/assets/img/logo.svg" alt="Client logo"
    /></router-link>

    <transition name="fade">
      <div class="gui" v-if="!modal && !overview && !detail">
        <div
          v-if="webVRCapable && !webVRActive"
          class="gui__webvr gui__element gui__element--top-center"
        >
          <a href="#!" @click.prevent="startWebVR">Bekijk in VR</a>
        </div>
        <div
          v-if="webVRActive"
          class="gui__webvr gui__element gui__element--top-center"
        >
          <a href="#!" @click.prevent="exitWebVR">Verlaat VR</a>
        </div>

        <div
          v-if="currentPano && currentPano.category"
          :class="`gui__title gui__element gui__element--bottom-left ${
            currentPano.category.description ? 'gui__title--description' : ''
          } ${descriptionOpen ? 'open' : ''}`"
        >
          <h1>{{ currentPano.category.title }}</h1>
          <ReadMore
            v-if="currentPano.category.description"
            :text="currentPano.category.description"
            :max="0"
            @read-less="descriptionOpen = false"
            @read-more="descriptionOpen = true"
            @open-detail="detail = true"
            :open="descriptionOpen"
          />
        </div>

        <a
          class="gui__overview gui__overview--open gui__element gui__element--top-right"
          href="#!"
          @click.prevent="
            () => {
              descriptionOpen = false;
              if (currentPano && currentPano.category) {
                detail = true;
              } else {
                overview = true;
              }
            }
          "
        >
          <div class="open-icon">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <span>{{ $t.overviewMenu }}</span>
        </a>

        <div
          class="logo__wrapper logo__wrapper--outside gui__element gui__element--bottom-right"
        >
          <img src="/assets/img/logo-text.svg" alt="Belfius text logo" />
        </div>
      </div>
    </transition>
    <div id="krpano"></div>
  </div>
</template>

<script>
import Vue from "vue";
import Overview from "@/components/Overview.vue";
import DetailedOverview from "@/components/DetailedOverview.vue";
import Modal from "@/components/Modal.vue";
import SplashScreen from "@/components/SplashScreen.vue";
import Hotspot from "@/components/Hotspot.vue";
import ReadMore from "@/components/ReadMore.vue";
import virtualTourApi from "@/api";
import {
  addSingleVrHotspot,
  addVrHotspots,
  removeVrHotspots,
} from "@/helpers/vr";
import { isTouchDevice } from "@/helpers";

export default {
  name: "Krpano",
  props: ["info"],
  components: {
    DetailedOverview,
    Overview,
    Modal,
    ReadMore,
    SplashScreen,
    Hotspot,
  },
  data() {
    return {
      showingTutorial: localStorage.getItem("tutorialShown") ? false : true,
      showingSplash: true,
      loading: true,
      overview: false,
      descriptionOpen: false,
      detail: this.$route.query.detail ? true : false,
      modal: false,
      modalContent: {},
      webVRCapable: false,
      webVRActive: false,
      panos: [],
      categories: [],
      panorama: this.$route.params.panorama
        ? this.$route.params.panorama
        : null,
      currentTour: null,
      currentPano: null,
      previousPano: null,
    };
  },
  mounted() {
    if (this.$route.params.tour) {
      // Tour selected - retrieve panos & init krpano
      this.fetchPanos();
    }
    this.initWindow();
  },
  beforeRouteUpdate(to, from, next) {
    this.descriptionOpen = false;
    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      let previousPano = this.panos.find((p) => p.slug == to.params.panorama);
      if (previousPano && this.currentPano !== previousPano) {
        this.currentPano = previousPano;
        window.krpanoObj.call(
          `loadscene('${previousPano.name}', null, MERGE, BLEND(0.5));`
        );
        this.sendGtmEvent(
          "pano-view",
          {
            tour_path: `/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`,
          },
          this.currentTour,
          this.currentPano.title
        );
      } else {
        history.back();
      }
    }
    next();
  },
  methods: {
    initWindow() {
      // Init loader
      window.startLoading = () => {
        this.loading = true;
      };
      window.stopLoading = () => {
        this.loading = false;
        this.showingSplash = false;
        // setTimeout(() => (this.loading = false), 500);
      };

      window.popStateDetected = false;
      window.addEventListener("popstate", () => {
        window.popStateDetected = true;
      });

      // Web VR init
      window.setVrReadyStatus = (vrStatus) => {
        this.webVRCapable = vrStatus;
      };

      // Init hotspots
      window.loadHotspot = async (spot, pano) => {
        let hotspot = (await virtualTourApi.getHotspot(pano.name, spot.name))
          .data;

        if (hotspot.linkedScene || hotspot.linkedPano) {
          let linkedPano = (
            await virtualTourApi.getPano(
              hotspot.linkedPano
                ? hotspot.linkedPano.panoName
                : hotspot.linkedScene
            )
          ).data;
          this.loadHotspotStyle(spot, hotspot, linkedPano);
        } else {
          this.loadHotspotStyle(spot, hotspot, null);
        }

        if (this.webVRActive) {
          addVrHotspots();
        }
      };

      // Handle VR hotspot click
      window.handleVrHotspotClick = (type, slug, name) => {
        switch (type) {
          case "navigation":
            this.loadPano(null, { name: name, slug: slug });
            break;
          case "exit_vr":
            this.exitWebVR();
            break;
          default:
            break;
        }
      };
    },
    async fetchPanos() {
      virtualTourApi.getPanosForTour(this.$route.params.tour).then((res) => {
        this.currentTour = res.data;
        this.$store.state.currentTour = this.currentTour;

        this.panos = res.data.panos;
        virtualTourApi.getCategories().then((res) => {
          this.categories = res.data;
        });

        if (this.panorama) {
          // Pano selected - init Krpano with certain pano
          let panoToShow = this.panos.find((p) => p.slug == this.panorama);
          this.currentPano = panoToShow;

          if (panoToShow) {
            // Pano is new so let load on init
            this.initTour(panoToShow);
            return;
          }
        }
        // No start pano selected - init Krpano from start
        this.currentPano = res.data.panos[0];
        let panoToShow = this.currentPano;
        this.initTour(panoToShow);
        return;
      });
    },
    initTour(startPano) {
      // Init krpano with the current tour
      var xml = `/assets/vtours/${
        this.currentTour.slug
      }/tour.xml?h=${+new Date()}`;
      let { embedpano } = window;

      embedpano({
        xml: xml,
        id: "krpanoObject",
        target: "krpano",
        html5: "only+webgl",
        mobilescale: 1.0,
        wmode: "opaque",
        passQueryParameters: true,
        consolelog: process.env.NODE_ENV === "development",
        mwheel: true,
        onready: (krpano) => {
          window.krpanoObj = krpano.get("global");
          if (isTouchDevice()) {
            krpanoObj.control.mouse = "drag";
            krpanoObj.control.touch = "drag";
          } else {
            krpanoObj.control.mouse = "follow";
            krpanoObj.control.touch = "follow";
          }
        },
        initvars: {
          basePath: "/assets/js",
          partialPath: "/assets/js/partials",
          pluginPath: "/assets/js/plugins",
          assetPath: "/assets/js/assets",
          dev: process.env.NODE_ENV === "development",
        },
      });

      if (startPano) {
        this.loadPano(null, startPano);
      } else if (!this.panorama) {
        this.panorama = this.currentPano.slug;
        this.$router.push({
          path: `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.panorama}`,
        });
      }

      if (!startPano) {
        this.sendGtmEvent(
          "pano-view",
          {
            tour_path: `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`,
          },
          this.currentTour,
          this.currentPano.title
        );
      }
    },
    loadPano(event, pano, lookat = null) {
      if (event) event.preventDefault();
      // if (!pano || this.currentPano === pano) return; // in order to not load pano when already there
      if (!pano) return;

      this.currentPano = pano;
      window.krpanoObj.call(
        `loadscene('${pano.name}', null, MERGE, BLEND(0.5));`
      );

      if (lookat && lookat.ath && lookat.atv) {
        window.krpanoObj.call(`lookat(${lookat.ath}, ${lookat.atv});`);
      }

      this.sendGtmEvent(
        "pano-view",
        {
          tour_path: `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`,
        },
        this.currentTour,
        this.currentPano.title
      );

      if (this.panorama !== pano.slug) {
        this.panorama = this.currentPano.slug;
        this.$router.push({
          path: `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${pano.slug}`,
        });
      }
    },
    async loadHotspotStyle(spot, hotspot, panorama) {
      hotspot.linkedPano = panorama;

      let template = `
      <div class="hotspot__wrapper">
        <div id="hotspot-${hotspot.id}"></div>
      </div>
      `;

      // Set basic config
      spot.type = "text";
      spot.renderer = "css3d";
      spot.html = template;
      spot.zoom = false;
      spot.distorted = false;

      // If I want a crosshair?
      // spot.flying = 1;

      if (spot.sprite) {
        // Set the id of the moving hotspot
        spot.sprite.id = `hotspot__locator--${hotspot.id}`;

        // Init hotspot component onto template
        const HotspotCtor = Vue.extend(Hotspot);
        const hotspotElement = new HotspotCtor({
          propsData: {
            tour: this.currentTour,
            pano: this.currentPano,
            hotspot: hotspot,
          },
          parent: this,
        }).$mount(`#hotspot-${hotspot.id}`);

        // Set click action
        // TODO: fix so no double click
        spot.onclick = () => {
          document.getElementById(hotspot.id).click();
        };

        // Listen to hotspot
        hotspotElement.$on("navigate", (linkedPano) => {
          this.loadPano(null, linkedPano);
        });
        hotspotElement.$on("info", (content) => {
          this.modalContent = content;
          this.descriptionOpen = false;
          this.modal = true;
        });
      }
    },
    startWebVR() {
      addVrHotspots();
      this.webVRActive = true;
      krpanoObj.call("webvr_onentervr();");
      krpanoObj.call("webvr.enterVR();");
      this.sendGtmEvent("webvr-open", { webvr_pano: this.currentPano.title });
    },
    exitWebVR() {
      removeVrHotspots();
      this.webVRActive = false;
      krpanoObj.call("webvr.exitVR();");
      krpanoObj.call("webvr_onexitvr();");
      krpanoObj.call("lookto(0, 0)");
    },
  },
};
</script>

<style lang="scss">
@import "../scss/gui.scss";

.krpano__wrapper {
  background: $color-accent;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    padding: 0;
    margin: 0;
  }

  // CSS Krpano hack
  & #krpanoObject {
    & > div {
      & > div:last-child {
        & > div {
          // #hotspot__wrapper
          width: 0 !important;
          height: 0 !important;
          background-color: transparent !important;
          border: 1px solid transparent;

          &:hover,
          &.locator__touched {
            z-index: 2005 !important;
          }

          & > div {
            & > div {
              overflow: visible !important;

              & > div {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

#krpano {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $basic-transition-200 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-splash-enter-active,
.fade-splash-leave-active {
  transition: opacity $basic-transition-1000;
}
.fade-splash-enter,
.fade-splash-leave-to {
  opacity: 0;
}

.overview {
  z-index: 3000;

  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity $basic-transition-500;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
}

.logo__wrapper {
  display: none;

  @include for-tablet-landscape-up {
    display: block;
  }
}
</style>
