<template>
  <div>
    <a
      v-for="locale in availableLocales"
      :key="locale"
      @click.prevent="selectLocale(locale)"
      :class="locale === selected ? 'selected' : ''"
    >
      {{ locale }}
    </a>
  </div>
</template>

<script>
import virtualTourApi from "@/api";

export default {
  name: "Languages",
  data() {
    return {
      selected: localStorage.getItem("locale")
        ? localStorage.getItem("locale")
        : this.$locale,
      availableLocales: [],
    };
  },
  mounted() {
    virtualTourApi
      .getAvailableLocales()
      .then((res) => (this.availableLocales = res.data));
  },
  methods: {
    selectLocale(locale) {
      this.selected = locale;
      localStorage.setItem("locale", locale);
      this.$locale = locale;
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.languages {
  padding: 2px;
  display: flex;
  flex-direction: column;
  color: $color-text-inverse;
  z-index: 1;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }

  a {
    cursor: pointer;
    opacity: 0.9;
    text-transform: uppercase;
    // transition: text-shadow $basic-transition-200;

    & ~ a {
      margin: 1rem 0;

      @include for-tablet-landscape-up {
        margin: 0;
      }
    }

    & ~ a::before {
      content: "";
      margin: 0;
      pointer-events: none;
      // text-shadow: none;
      font-weight: normal !important;

      @include for-tablet-landscape-up {
        content: "|";
        margin: 0 6px;
      }
    }

    &.selected {
      pointer-events: none;
    }

    &.selected,
    &:hover {
      opacity: 1;
      // text-shadow: 0 0 1px $color-text-inverse;
      font-weight: bold;
    }

    &:hover::before {
      // text-shadow: none;
      font-weight: normal !important;
      opacity: 0.9;
    }
  }
}
</style>
